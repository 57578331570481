<template>
  <div class="linePopup">
    <el-form ref="form" :model="form" :rules="rules" label-width="100px">
      <el-form-item label="服务商名称" prop="name">
        <el-input class="width-280" v-model="form.name" size="small" placeholder="请输入服务商名称" />
      </el-form-item>
      <div class="rolling-box">
        <el-form-item label="线路模式" prop="mode">
          <el-select v-model="form.mode" class="line-el-select width-280" size="small" placeholder="请选择线路模式">
            <el-option v-for="item in modeList" :key="item.code" :label="item.remarks" :value="item.code">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="小号月租" prop="monthlyRent">
          <div class="flex-nowrap-flex-start">
            <div class="el-input-width pl-20">
              <el-input type="number" v-model="form.monthlyRent" size="small" placeholder="金额"></el-input>
            </div>
            <div class="unit-box">元/分钟</div>
          </div>
        </el-form-item>
        <el-form-item label="是否报备" prop="">
          <el-switch size="mini" active-text="是" inactive-text="否" class="m-l-10 m-r-10" inline-prompt
            v-model="form.report" :active-value="1" :inactive-value="0" @click="$forceUpdate">
          </el-switch>
        </el-form-item>
        <el-form-item label="主叫敏感地区" prop="telAAreaIntercept">
          <el-input class="width-280" v-model="form.telAAreaIntercept" size="small"
            placeholder="请填写主叫敏感地区(省/市+运营商 : 北京电信,),以英文逗号分割" />
        </el-form-item>
        <el-form-item label="被叫敏感地区" prop="telAAreaIntercept">
          <el-input class="width-280" v-model="form.telBAreaIntercept" size="small"
            placeholder="请填写被叫敏感地区(省/市+运营商 : 北京电信,),以英文逗号分割" />
        </el-form-item>
        <el-form-item label="并发限制">
          <div class="flex-nowrap-flex-start">
            <div>1</div>
            <div class="num-input">:</div>
            <div class="num-input">
              <el-input-number v-model="form.concurren" :min="1" :max="300" size="small" controls-position="right"
                @change="handleChange" />
            </div>
          </div>
        </el-form-item>
        <el-form-item label="资费设置">
          <div class="flex-nowrap-flex-start">
            <el-button size="small" @click="addCharges">
              <icon-font type="icon-tianjia" />
              添加更多资费模式
            </el-button>
            <div class="icon-help-box">
              <icon-font type="icon-bangzhu1" class="icon-help" />
              <div class="icon-help-text">
                <div class="triangle"></div>
                <div>帮助：点击添加按钮即可添加多条资费模式（例：含录音模式资费）</div>
              </div>
            </div>
          </div>
          <div v-for="(option,o) in form.options" :key="o">
            <div class="flex-nowrap-flex-start">
              <div class="buy-name">资费模式{{o+1}}</div>
              <div v-show="o != 0" class="delete-text" @click="deleteItem(o)">删除</div>
            </div>
            <el-form ref="option" :model="option" :rules="rules" label-width="100px">
              <el-form-item label="通话资费">
                <div class="flex-nowrap-flex-start">
                  <div class="el-input-width pl-20">
                    <el-input v-model="form.options[o].basic" size="small" placeholder="金额"
                      @input="getNameValue(form.options[o].basic,null,null,o)"></el-input>
                  </div>
                  <div class="unit-box">元/分钟</div>
                </div>
              </el-form-item>
              <el-form-item label="其他资费">
                <div class="el-input-width ">
                  <el-select v-model="option.surcharge" multiple size="small" collapse-tags style="margin-left: 20px"
                    placeholder="选择其他资费">
                    <el-option v-for="item in surchargeList" :key="item.name" :label="item.value" :value="item">
                    </el-option>
                  </el-select>
                </div>
              </el-form-item>
              <el-form-item label="">
                <div class="flex-nowrap-flex-start" v-for="(item,i) in option.surcharge" :key="i">
                  <div class="el-input-width pl-20">
                    <el-input v-model="option.surcharge[i][item.name]"
                      @input="getNameValue(form.options[o].basic,option.surcharge,option.surcharge[i],o)" size="small">
                      <template #prepend>{{item.value}}</template>
                    </el-input>
                  </div>
                  <div class="unit-box">元/分钟</div>
                </div>
              </el-form-item>
              <el-form-item label="最终费用">
                <div class="flex-nowrap-flex-start">
                  <div class="pl-20">
                    {{ form.options[o].final == ''?'0':form.options[o].final }}
                  </div>
                  <div class="unit-box">元/分钟</div>
                </div>
              </el-form-item>
            </el-form>
          </div>
        </el-form-item>
        <el-form-item label="线路详情" prop="description">
          <Wangeditor class="width-280" v-model="form.description" :content="form.description"
            @inputs="getWangEditorValue"></Wangeditor>
        </el-form-item>
      </div>
      <el-form-item>
        <div class="button-box flex-nowrap-flex-end">
          <el-button class="button-left" @click="resetForm('form')">取消</el-button>
          <el-button class="button-right" @click="submitForm('form')">{{ type == 'set' ? '确认' : '修改' }}</el-button>
        </div>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { IconFont } from '@/utils/iconfont'
import Wangeditor from '@/components/Wangeditor.vue'
import dictionary from '@/api/system/dictionary'
import amount from '@/api/open/amount/amount'
export default {
  name: 'linePopup',
  components: {
    IconFont,
    Wangeditor,
  },
  props: {
    type: {
      type: String,
      default: 'set',
    },
  },
  data() {
    return {
      form: {
        report: '',
        name: '',
        monthlyRent: '',
        concurren: 1,
        options: [{ basic: '', surcharge: [], final: '' }],
        mode: '',
        description: '',
        telAAreaIntercept: '',
        telBAreaIntercept: '',
      },
      modeList: [],
      surchargeList: [],
      value1: [],
      repeatClick: true,
      content: '',
      rules: {
        name: [
          {
            required: true,
            message: '请填写服务商名称',
            trigger: 'blur',
          },
          {
            min: 1,
            max: 100,
            message: '名称长度不超过100个字符',
            trigger: 'blur',
          },
        ],
        mode: [
          {
            required: true,
            message: '请选择线路模式',
            trigger: 'blur',
          },
        ],
        monthlyRent: [
          {
            required: true,
            message: '请填写月租',
            trigger: 'blur',
          },
        ],
        description: [
          {
            required: true,
            message: '请填写详情',
            trigger: 'blur',
          },
        ],
        telAAreaIntercept: [
          {
            required: false,
            message:
              '请填写主叫敏感地区(省/市+运营商 : 北京电信,),以英文逗号分割',
            trigger: 'blur',
          },
        ],
        telBAreaIntercept: [
          {
            required: false,
            message:
              '请填写被叫敏感地区(省/市+运营商 : 北京电信,),以英文逗号分割',
            trigger: 'blur',
          },
        ],
      },
      arr: [{ record: '0.4' }],
    }
  },
  mounted() {
    if (this.type == 'set') {
      this.getModeList()
    } else {
      this.getModeList()
      this.getAmountDetails(this.type)
    }
  },
  methods: {
    //获取服务商详情
    getAmountDetails(id) {
      amount
        .amountGet({
          id: id,
        })
        .then((res) => {
          console.log('res :>> ', res)
          this.form.name = res.data.name
          this.form.telAAreaIntercept = res.data.telAAreaIntercept
          this.form.telBAreaIntercept = res.data.telBAreaIntercept

          this.form.monthlyRent = res.data.monthlyRent
          this.form.concurren = res.data.concurren
          this.form.mode = res.data.mode
          this.form.description = res.data.description
          this.form.options = JSON.parse(res.data.options)
          this.form.id = res.data.id
          this.form.report = res.data.report
        })
    },
    // 获取线路模块表
    getModeList() {
      dictionary
        .queryName({
          type: 'mode',
        })
        .then((res) => {
          this.modeList = res.data
        })
      dictionary
        .query({
          type: 'surcharge',
        })
        .then((res) => {
          this.surchargeList = res.data
          this.surchargeList.forEach((item, i) => {
            this.surchargeList[i][item.name] = 0
          })
        })
    },
    submitForm(formName) {
      if (this.repeatClick == true) {
        this.repeatClick = false
        this.$refs[formName].validate((valid) => {
          if (valid) {
            if (this.type == 'set') {
              if (this.form.monthlyRent >= 0) {
                amount
                  .amountAdd({
                    name: this.form.name,
                    monthlyRent: this.form.monthlyRent,
                    concurren: this.form.concurren,
                    options: JSON.stringify(this.form.options),
                    mode: this.form.mode,
                    description: this.form.description,
                    report: this.form.report,
                    telAAreaIntercept: this.form.telAAreaIntercept,
                    telBAreaIntercept: this.form.telBAreaIntercept,
                  })
                  .then((res) => {
                    this.$notify({
                      title: '成功',
                      message: '服务商新增成功！',
                      type: 'success',
                    })
                    this.repeatClick = true
                    this.$emit('addSuccess', 'ok')
                  })
                  .catch((err) => {
                    this.repeatClick = true
                  })
              } else {
                this.$notify({
                  title: '失败',
                  message: '小号月租不能为负',
                  type: 'error',
                })
                this.repeatClick = true
              }
            } else {
              if (this.form.monthlyRent >= 0) {
                amount
                  .amountEdit({
                    name: this.form.name,
                    monthlyRent: this.form.monthlyRent,
                    concurren: this.form.concurren,
                    options: JSON.stringify(this.form.options),
                    mode: this.form.mode,
                    id: this.form.id,
                    description: this.form.description,
                    report: this.form.report,
                    telAAreaIntercept: this.form.telAAreaIntercept,
                    telBAreaIntercept: this.form.telBAreaIntercept,
                  })
                  .then((res) => {
                    this.$notify({
                      title: '成功',
                      message: '服务商修改成功！',
                      type: 'success',
                    })
                    this.repeatClick = true
                    this.$emit('addSuccess', 'ok')
                  })
                  .catch((err) => {
                    this.repeatClick = true
                  })
              } else {
                this.$notify({
                  title: '失败',
                  message: '小号月租不能为负',
                  type: 'error',
                })
                this.repeatClick = true
              }
            }
          } else {
            this.$notify({
              title: '注意',
              message: '请选择线路模式',
              type: 'warning',
            })
            this.repeatClick = true
            return false
          }
        })
      } else {
        this.$notify({
          title: '注意',
          message: '请不要重复提交，服务商正在创建中！',
          type: 'warning',
        })
      }
    },
    resetForm(formName) {
      this.$refs[formName].resetFields()
      this.$emit('resetForm', 'ok')
    },
    getWangEditorValue(val) {
      // console.log(val)
      this.form.description = val
    },
    handleChange() {},
    addCharges() {
      this.form.options.push({})
    },
    getFinal(basic, surcharge) {
      // console.log(this.keyName)
      // console.log(surcharge)
      // let price = 0;
      // let newArrs = JSON.parse(JSON.stringify(surcharge));
      // this.keyName.forEach((item,i)=>{
      //     newArrs.forEach((sitem,s) =>{
      //         for(let a in sitem){
      //             // console.log(a)
      //             if(a != item){
      //                 price = sitem[sitem.name] + price
      //                 console.log(sitem[sitem.name])
      //             }
      //         }
      //     })
      // })
      // console.log(price)
    },
    getNameValue(a, b, c, o) {
      if (a == null || a == '') {
        b.forEach((item, i) => {
          this.form.options[o].final = parseFloat(item[c.name])
        })
      } else if (b == null || c == null) {
        this.form.options[o].final = a
      } else {
        b.forEach((item, i) => {
          if (item[c.name] == '') {
            this.form.options[o].final = parseFloat(a).toFixed(3)
          } else {
            this.form.options[o].final = (
              parseFloat(item[c.name]) + parseFloat(a)
            ).toFixed(3)
          }
        })
      }
    },
    // 删除元素
    deleteItem(o) {
      if (o != 0) {
        this.form.options.splice(o, 1)
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.line-el-select {
  width: 80%;
}
.num-input {
  margin-left: 16px;
}
.icon-help-box {
  margin-left: 20px;
  cursor: pointer;
  position: relative;
  .icon-help {
    font-size: 18px;
  }
  .icon-help-text {
    display: none;
  }
}
.icon-help-box:hover .icon-help-text {
  display: block;
  position: absolute;
  top: 45px;
  left: -13px;
  z-index: 20;
  width: 240px;
  padding: 16px;
  border-radius: 0.5rem;
  opacity: 0.75;
  background: #000000;
  box-shadow: 0px 3px 28px 0px rgba(0, 0, 0, 0.1);
}
// 三角形
.triangle {
  position: absolute;
  top: -12px;
  left: 15px;
  border-style: solid;
  border-width: 0px 8px 12px 8px;
  opacity: 1.52;
  // background: #000000;
  border-color: transparent transparent #000000 transparent;
  width: 0px;
  height: 0px;
}
.el-input-width {
  width: 220px;
}
.unit-box {
  margin-left: 9px;
  font-size: 12px;
  font-weight: 400;
  color: #333333;
}
.rolling-box {
  height: 450px;
  padding-right: 16px;
  overflow: overlay;
}
.button-left {
  color: #333333;
  background: #f2f3f8;
}
.button-right {
  color: #ffffff;
  background: #637dff;
}
.delete-text {
  margin-left: 30px;
  cursor: pointer;
  color: #f56c6c;
}
.button-box {
  background: #fff;
  height: 60px;
}
.buy-name {
  font-size: 14px;
  font-weight: 700;
  color: #637dff;
}
.width-280 {
  width: 380px;
}
</style>